.accent-slate {
	--accent: 210 40% 96.1%;
}

.accent-slate.dark {
	--accent: 217.2 32.6% 17.5%;
}

.accent-cocoon {
	--primary: 3 100% 61%;
}

.accent-indigo {
	--primary: 226 52% 46%;
}

.accent-fuchsia {
	--primary: 292 84% 61%;
}

.accent-kaki {
	--primary: 110 12.8 36.9;
}
