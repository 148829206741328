@import "./tailwind.css";

html,
body,
#__next {
	scroll-behavior: smooth;
	scroll-padding-top: 90px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
	-webkit-font-smoothing: antialiased;
}

#__next {
	height: auto;
	min-height: 100%;
}

.lucide {
	stroke-width: 1.5;
}

@media print {
	@page {
		size: A4;
		margin: 0;
		overflow-y: auto;
	}

	html,
	body,
	#__next {
		display: block;
		height: auto;
	}
}
