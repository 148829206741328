@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0, 0%, 100%;
		--foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 0 0% 100%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;

		--radius: 10px;

		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
		--chart-6: 280 65% 65%;
		--chart-7: 150 55% 45%;
		--chart-8: 35 85% 60%;
		--chart-9: 190 70% 50%;
		--chart-10: 330 75% 60%;
		--chart-11: 220 60% 55%;
		--chart-12: 90 65% 45%;
		--chart-13: 15 80% 55%;
		--chart-14: 160 60% 40%;
		--chart-15: 300 70% 55%;

		--type-event: #ff4338;
		--type-visit: #94a3b8;
		--type-quotation: #06b6d4;
		--type-presta: #a855f7;
		--type-desk: #f9c846;

		/* Custom */
		--success: 152 60% 65%;
		--warning: 36.94 100% 50%;

		/* Syntax Highlight */
		--sh-class: #7e57c2;
		--sh-identifier: #b4bdc7;
		--sh-sign: #6c7a89;
		--sh-property: #64b5f6;
		--sh-entity: #4db6ac;
		--sh-jsxliterals: #9575cd;
		--sh-string: #81c784;
		--sh-keyword: #ff7043;
		--sh-comment: #78909c;
	}

	.dark {
		--background: 222 47.4% 11.2%;
		--foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--primary: 215 16% 47%;
		--primary-foreground: 210 40% 98%;

		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;

		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;

		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;

		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;

		/* Custom */
		--success: 152 60% 65%;
		--warning: 36.94 100% 50%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}
